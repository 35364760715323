import { Theme } from 'app'
import { React, View, StyleSheet, Text, Input, Button, Validate, useState } from 'lib'
import { ContactForm } from 'actions'

const initialState = {
  name: '',
  email: '',
  description: '',
  status: 'ready',
}

const statusDict = {
  ready: 'SEND',
  sending: 'Sending...',
  done: 'Thank you! We will be in touch shortly',
  invalid: 'Oops! Please check above',
  error: 'Could not send message! Try again?',
}

const ContactPage: React.FC = ({ children }) => {
  const [state, setState] = useState(initialState)
  const isMobile = Theme.hooks.down('mid')

  function validateForm() {
    if (Validate.field(state.name)) return false
    else if (Validate.email(state.email)) return false
    else if (Validate.field(state.description)) return false
    else return true
  }

  const onChange = (e, key) => {
    const newState = Object.assign({}, state)
    newState[key] = e
    if (newState.status != 'sending') {
      newState.status = 'ready'
    }
    setState(newState)
  }

  function handleSubmit() {
    log({ state })
    if (validateForm()) {
      setState({ ...state, status: 'sending' })
      const onSuccess = () => {
        setState({ ...initialState, status: 'done' })
      }
      const onFailure = () => setState({ ...state, status: 'error' })
      ContactForm.submit({ data: state, onSuccess, onFailure })
    } else {
      log('invalid form data')
      setState({ ...state, status: 'invalid' })
    }
  }

  const buttonText = state.status in statusDict ? statusDict[state.status] : statusDict.ready
  const edited = state.status == 'invalid'
  const loading = state.status == 'sending'
  const done = state.status == 'done'

  const commonInputProps = {
    textStyle: isMobile ? styles.inputTextMobile : styles.inputText,
    style: styles.inputContainer,
    variant: 'pill',
    validate: Validate.field,
    edited,
    reset: done,
  }

  return (
    <View variant={'flex column'} style={styles.wrapper}>
      {children}

      <Text variant={'h4'} text='Have a question or Suggestion?' style={styles.formTitle}/>

      <View variant={'flex column'} style={styles.formWrapper}>
        <Input
          {...commonInputProps}
          placeholder='Your name'
          validatorMessage={'Please provide us your name'}
          value={state.name}
          onChangeText={(e) => onChange(e, 'name')}
        />
        <Input
          {...commonInputProps}
          placeholder='Your e-mail'
          validatorMessage={'Please provide us a valid e-mail address'}
          validate={Validate.email}
          value={state.email}
          onChangeText={(e) => onChange(e, 'email')}
        />
        <Input
          {...commonInputProps}
          multiline
          placeholder='Type your message here'
          textStyle={{ ...commonInputProps.textStyle, ...styles.multiline }}
          validatorMessage={'Please provide us a description'}
          value={state.description}
          onChangeText={(e) => onChange(e, 'description')}
        />

        <Button
          style={{ borderRadius: Theme.values.borderRadius }}
          variant={'gradient responsive noDecoration uppercase justifyCenter white'}
          loading={loading}
          text={buttonText}
          onPress={handleSubmit}
          disabled={done}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {
    height: '100%',
    gap: Theme.spacing(5),
  },
  formTitle: {
    textTransform: 'capitalize',
  },
  formWrapper: {
    gap: Theme.spacing(2),
  },
  inputContainer: {
    borderWidth: 1,
    padding: Theme.spacing(1.3),
  },
  multiline: {
    maxHeight: 150,
    minHeight: 150,
    flexShrink: 0,
    boxSizing: 'border-box',
    resize: 'none',
    whiteSpace: 'pre-line',
  },
  inputText: {
    fontSize: 20,
  },
  inputTextMobile: {
    fontSize: 18,
  },
})

export default ContactPage
