import { StaticImage } from 'gatsby-plugin-image'
import { React, View, SEO, CenterWrapper, StyleSheet, Text } from 'lib'
import { Theme, HTMLStylesDark, Settings } from 'app'
import { PageTitle } from 'components'
import { MailIcon } from 'assets/icons'

import ContactForm from './ContactForm'

function Contact() {
  return (
    <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.innerWrapper}>
      <SEO doNotTrack title={'Contact Us'} description={"Do you have any question? We're open for questions"}/>
      <View style={styles.staticImageWrapper} up={'mid'}>
        <StaticImage
          src='../../assets/mixedscreenshot.png'
          alt='Becon app route'
          imgStyle={styles.screenshot}
          style={styles.imageWrapper}
          layout='constrained'
        />
      </View>
      <ContactForm>
        <View variant={'flex column'} style={styles.formInnerWrapper}>
          <PageTitle location='Contact us' title='get in touch'/>
          <View variant={'flex alignCenter '}>
            <MailIcon size={24} color={Theme.colors.grad1}/>
            <Text variant={'marginLeft:2 p2'} text={Settings.CONTACT_EMAIL}/>
          </View>
        </View>
      </ContactForm>
    </CenterWrapper>
  )
}

export default Contact

const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {
    ...Theme.flex,
    marginTop: Theme.spacing(8),
    marginBottom: Theme.spacing(5),
    minHeight: `calc(100vh - ${Theme.values.headerMenuMargin} - ${Theme.values.headerMenuHeight})`,
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(6),
      marginBottom: Theme.spacing(5),
    },
  },
  innerWrapper: {
    ...HTMLStylesDark.plainText,
    marginTop: Theme.spacing(8),
    gap: Theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
  },
  screenshot: {
    maxWidth: 400,
    maxHeight: 600,
    marginTop: Theme.spacing(1),
  },
  imageWrapper: {
    maxWidth: 400,
    maxHeight: 600,
    flex: 1,
    display: 'flex',
  },
  staticImageWrapper: {
    flex: 1,
    display: 'flex',
    ...Theme.center,
    [Theme.media.down('small')]: {
      display: 'none',
    },
  },
  formInnerWrapper: {
    gap: Theme.spacing(3),
  },


})
