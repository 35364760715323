import { React, Page } from 'lib'
import ContactPage from './_contact'

function Contact() {
  return (
    <Page whiteBackground>
      <ContactPage/>
    </Page>
  )
}

export default Contact
